import Gumshoe from 'gumshoejs';

var $ = require("jquery")
let mute = false;

$(document).on('turbolinks:load', function () {
    $('nav.desktop-only li.active').removeClass('active');
    $('a[href="' + location.pathname + '"]').closest('li').addClass('active');

    $('.product_button').on('click', function () {
        $(this).closest('.product-main-info').addClass('d-none')
        this.closest('.product_info').querySelector('.images-col').classList.add('desktop-only')
        $(this).closest('.product-main-info').parent('div').children('.product-details').removeClass('d-none')
    })

    $('.product_button_back').on('click', function () {
        $(this).closest('.product-details').addClass('d-none')
        this.closest('.product_info').querySelector('.images-col').classList.remove('desktop-only')
        $(this).closest('.product-details').parent('div').children('.product-main-info').removeClass('d-none')
    })

    // Product packs images change
    let packSizesLinks = $('.pack_sizes_links a')
    packSizesLinks.on('click', function (event) {
        event.preventDefault()
        let color = $(this).siblings('.accent_color').text();
        $(this).parent().children('a').css('color', 'white')
        $(this).css('color', color)
    })

    $('.small-pack-link').on('click', function () {
        this.closest('.product').querySelectorAll('.small_package').forEach((x) => x.classList.remove('d-none'))
        this.closest('.product').querySelectorAll('.big_package').forEach((x) => x.classList.add('d-none'))
        this.closest('.product').querySelector('.details-small').classList.remove('d-none')
        this.closest('.product').querySelector('.details-large').classList.add('d-none')
    })

    $('.big-pack-link').on('click', function () {
        this.closest('.product').querySelectorAll('.small_package').forEach((x) => x.classList.add('d-none'))
        this.closest('.product').querySelectorAll('.big_package').forEach((x) => x.classList.remove('d-none'))
        this.closest('.product').querySelector('.details-small').classList.add('d-none')
        this.closest('.product').querySelector('.details-large').classList.remove('d-none')
    })

    let header = document.querySelector('nav.navbar');
    if (window.location.pathname.split("/").length === 2) {
        let spy = new Gumshoe('.color-pickers a', {
            offset: function () {
                return header.getBoundingClientRect().height;
            }
        });
    }

    let currentSound = 0;
    $('.color-pickers li').on('click', function () {
        $('.color-pickers li').removeClass('active')
        $(this).addClass('active')
        if (!mute) {
            document.querySelectorAll('.audio-tags audio')[currentSound].play();
            currentSound = currentSound === 3 ? 0 : currentSound + 1
        }
    })

    // Mobile menu
    $('#menuToggle').on('click', function () {
        $(this).toggleClass('open');
        let menu = $('#menuOverlay')
        if (menu.hasClass('slide-in')) {
            menu.removeClass('slide-in');
            menu.addClass('slide-out');
        } else if (menu.hasClass('slide-out')) {
            menu.removeClass('slide-out');
            menu.addClass('slide-in');
        } else {
            menu.addClass('slide-in');
            menu.removeClass('d-none')
        }
    })

    // Actions slider
    $('.tarkikComandSlider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 4,
                    vertical: true,
                    arrows: false
                }
            }
        ]
    });

    $('.scroll-top').click(() => {
        $("html, body").animate({scrollTop: 0}, "slow");
    });

    $('.volume-icons div').on('click', function(){
        console.log("asd");
        $('.volume-icons div').removeClass('d-none')
        $(this).addClass('d-none')
    })

    $('#muteIcon').on('click', () => {
        mute = true;
    })

    $('#unmuteIcon').on('click', () => {
        mute = false;
    })

    // $('#productionLink').click((e) => {
    //     console.log("asd");
    //     e.preventDefault();
    //     let productionOverlay = $('#productionOverlay')
    //     if (productionOverlay.hasClass('slide-in-prod')) {
    //         productionOverlay.removeClass('slide-in-prod');
    //         productionOverlay.addClass('slide-out');
    //     } else if (productionOverlay.hasClass('slide-out')) {
    //         productionOverlay.removeClass('slide-out');
    //         productionOverlay.addClass('slide-in-prod');
    //     } else {
    //         productionOverlay.addClass('slide-in-prod');
    //         productionOverlay.removeClass('d-none')
    //     }
    //     return e.preventDefault();
    // })

})

$(document).on('turbolinks:click', function (event) {
    if (event.target.getAttribute('href').includes('#')) {
        return event.preventDefault()
    }
})